@import url("https://fonts.googleapis.com/css2?family=Coiny&family=Poppins:wght@700;800;900&family=Share+Tech&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Coiny&family=Poppins:wght@300;400;700;800;900&family=Share+Tech&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: poppins, share tech;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(./assets/cage.png), #090529;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* For Chrome, Safari, Edge, and other WebKit-based browsers */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

* {
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
 
  
}


.navbar-container .link {
  color: #fff;
  font-size: 1.3rem;
  width: 150px;
  display: inline-block; /* Ensures the width is respected */
  text-align: center; /* Center text inside the link */
  text-decoration: none; /* Remove underline */
  position: relative; /* For positioning the pseudo-element */
  padding: 8px 0; /* Padding to adjust height without affecting layout */
  transition: color 0.2s; /* Smooth color transition */
}

.navbar-container .link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #E5B4FF;
  color: #000;
  transform: scaleX(0); /* Start with no width */
  transform-origin: bottom; /* Scale from bottom */
  transition: transform 0.3s ease; /* Smooth expansion */
  z-index: -1; /* Behind the text */
  border-radius: 5px; /* Rounded corners */
}

.navbar-container .link:hover::before {
  transform: scaleX(1); /* Expand to full width on hover */
}

.navbar-container .link:hover {
  color: #000; /* Change text color on hover */
  padding: 8px 15px; /* Add padding on hover */
}




.Header {
  position: sticky;
}

.priya_maamphoto{
  height: 100px;
}